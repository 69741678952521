import React, { useEffect } from 'react';
import './DataAnalyst.css';

const DataAnalyst = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section class="after-banner ptCustom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="content">
              <h1>Responsible AI Co-worker</h1>
              <p className='text-dark'> At AgentAnalytics.AI, we are tracking regulation and compliance required around the world such as EU AI act in 2023, Hiroshima AI process by G7 countries and develope automated Responsible AI Agents for building AI products responsibly. This would bring in efficiency in multiple industries such as Life Science industry where drug development as long gestation period. 
</p>
            </div>
          </div>
          <div class="col-md-6">
                                <div class="feature-img">
                                <video width="560" height="400" autoPlay muted>
                                        <source src="\vid\responsive.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                </video>
                                </div>
                            </div>

        


          <div className='col-md-12 my-4'>
            <p className='text-dark'>Responsible AI, also known as Ethical AI,  focuses on developing and using AI in a way that is beneficial to society and minimizes potential risks. AI systems should be trained on diverse datasets to minimize bias against any particular group. This helps ensure fair and equal treatment for everyone interacting with the AI.  It's important to understand how AI algorithms arrive at decisions. This allows for identifying and mitigating potential biases and ensuring fairness in the outcomes. Responsible AI practices help minimize potential risks associated with AI, such as bias, discrimination, and unintended consequences.
</p>
          </div>
        </div>
      </div>
    </section>
  );

};

export default DataAnalyst;
