import './Header.css';
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();

    const scrollAboutSection = () => {
        const aboutSection = document.getElementById('aboutSection');
        aboutSection.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollTodataSection = () => {
        const dataSection = document.getElementById('dataSection');
        dataSection.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollServiceSection = () => {
        const serviceSection = document.getElementById('serviceSection');
        serviceSection.scrollIntoView({ behavior: 'smooth' });
    };

    const handleContactClick = () => {
        const email = 'ranjan.relan@agentanalytics.ai';
        const subject = 'Regarding booking a demo of your Agent';
        const body = 'Hello, I would like to get in touch to get a demo of your MLOps agent.';
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoLink);
      };

  return (
    <>
    <header className="App-header">
       <nav class="navbar navbar-expand-lg">
        <div class="container">
            <div class="d-flex align-items-center justify-content-between w-100">
                <div class="logo">
                <Link to="/" class="navbar-brand" href="#">
                        <img src="images/logo.png" alt="logo" />
                    </Link>
                </div>
                <div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">

                            {location.pathname === '/service' || location.pathname === '/dataAnalyst' || location.pathname === '/mlAgent' ? (
                                <Link to="/" className="nav-link">
                                    About
                                </Link>
                            ) : (
                                <Link to="#" onClick={scrollAboutSection} className="nav-link">
                                    About
                                </Link>
                            )}

                            </li>
                            <li class="nav-item dropdown">
                                
                                {location.pathname === '/service' || location.pathname === '/dataAnalyst' || location.pathname === '/mlAgent' ? (
                                    <Link to="/" className="nav-link">
                                        Products
                                    </Link>
                                        ) : (
                                            <Link to="#" onClick={scrollTodataSection} className="nav-link">
                                                Products
                                            </Link>
                                 )}
                            </li>
                            <li class="nav-item">

                                {location.pathname === '/service' || location.pathname === '/dataAnalyst' || location.pathname === '/mlAgent' ? (
                                    <Link to="/" className="nav-link">
                                        Services
                                    </Link>
                                        ) : (
                                            <Link to="#" onClick={scrollServiceSection} className="nav-link">
                                                Services
                                            </Link>
                                 )}

                            </li>
                            <li class="nav-item">
                                <a class="nav-link">Blog</a>
                            </li>
                        </ul>
                        <div class="nav-btn mx-3">
                            <Link class="" onClick={handleContactClick}>Contact Us</Link>
                        </div>
                        <div class="lang">
                            <span class="d-flex align-items-center gap-2">
                                <img src="images/globe.png" /> EN
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    </header>
    </>
  );
}

export default Header;
