import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {

    const handleContactClick = () => {
        const email = 'Ranjan.relan@agentanalytics.ai';
        const subject = 'Regarding your website';
        const body = 'Hello, I would like to get in touch regarding your website.';
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoLink);
    };

    return (
        <>
            <div className="App h-100">
                <section className=' h-100' id="aboutSection">
                    <div id="carouselExampleCaptions" class="carousel slide h-100" data-bs-ride="carousel">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div class="carousel-inner h-100">
                            <div class="carousel-item active h-100">
                                <img src="images/bg2.jpg" class="d-block w-100 vh-100 objectCover" alt="..." />
                                <div class="carousel-caption h-100 d-none d-md-block">
                                    <div class="banner-content bg-style h-100 d-flex align-items-center">
                                        <div class="text-start container">
                                        <h1>World's First Conversational</h1>
                                            <h1>AI Co-workers</h1>
                                            <h1>for Data & Analytics</h1>
                                            <p>Connect with powerful agents to get fast and efficient solutions.</p>
                                            <div class="nav-btn mt-4">
                                            <Link onClick={handleContactClick}>Book a Demo</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item h-100">
                                <img src="images/bg3.jpg" class="d-block w-100 vh-100 objectCover" alt="..." />
                                <div class="carousel-caption h-100 d-none d-md-block">
                                    <div class="banner-content bg-style h-100 d-flex align-items-center">
                                        <div class="text-start container">
                                            <h1>World's First Conversational</h1>
                                            <h1>AI Co-workers</h1>
                                            <h1>for Data & Analytics</h1>
                                            <p>Connect with powerful agents to get fast and efficient solutions.</p>
                                            <div class="nav-btn mt-4">
                                            <Link onClick={handleContactClick}>Book a Demo</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item h-100">
                                <img src="images/bg-main.jpg" class="d-block w-100 vh-100 objectCover" alt="..." />
                                <div class="carousel-caption h-100 d-none d-md-block">
                                    <div class="banner-content bg-style h-100 d-flex align-items-center">
                                        <div class="text-start container">
                                            <h1>World's First Conversational</h1>
                                            <h1>AI Co-workers</h1>
                                            <h1>for Data & Analytics</h1>
                                            <p>Connect with powerful agents to get fast and efficient solutions.</p>
                                            <div class="nav-btn mt-4">
                                            <Link onClick={handleContactClick}>Book a Demo</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </section>

                <section class="after-banner py-5 my-5">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="content">
                                    <h4>Our Company</h4>
                                    <p> AgentAnalytics.AI is an Enterprise Deeptech B2B SaaS platform for Data & Analytics. Both the </p>
                                    <h4>Our Founders</h4>
                                    <p> Both Co-founders have more than 16+ years of experience working in Data & Analytics and have background in consulting and technology industry</p>
                                    <div class="arrow d-flex align-items-center gap-2">
                                        <img class="arrow-img" src="images/arrow.png" />
                                        <img class="ar-logo" src="images/logo.png" />
                                        <p class="m-0">get access now</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="plat-img">
                                    <img src="images/mockup.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="content-image" id="dataSection">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="feature">
                                    <h6></h6>
                                    <h2>Responsible AI Co-worker</h2>
                                    <p>At AgentAnalytics.AI, we are tracking regulation and compliance required around the  world such as EU AI act in 2023, Hiroshima AI process by G7 countries and develope automated Responsible AI Agents for building AI products responsibly.  This would bring in efficiency  in multiple industries such as Life Science industry where drug development as long gestation period.</p>
                                    <div class="nav-btn text-center mt-4">
                                        <Link to="/dataAnalyst" class="blue-btn" href="#">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="feature-img">
                                <video width="560" height="400" autoPlay muted>
                                        <source src="\vid\responsive.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            
                <section class="py-5">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="feature-img">
                                <video width="560" height="400" autoPlay muted>
                                        <source src="\vid\mlops.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                </video>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="feature">
                                    <h6></h6>
                                    <h2>MLOps AI Co-worker</h2>
                                    <p>AI Summer is here and more than million models have been created in last decade. With more AI models and foundational models on the way, world needs an AI Adoption Co-worker which can help productionize your model in fast, effective and secure way.</p>
                                    <div class="nav-btn text-center mt-4">
                                        <Link to="/mlAgent" class="blue-btn" href="#">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="content-image" id="serviceSection">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="feature">
                                    <h6></h6>
                                    <h2>Product Manager AI Co-worker</h2>
                                    <p>Our Product Manager AI Co-worker would be overall responsible for working with human Product Managers to curate ideas, generate & suggest personalized ideas. This AI Product Manager would also divide tasks among multiple AI Co-workers and monitor their performance and deliver a holistic experience.</p>
                                    <div class="nav-btn text-center mt-4">
                                        <Link to  class="blue-btn" >coming soon</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="feature-img">
                                <video width="560" height="400" autoPlay muted>
                                        <source src="\vid\manager.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="build-text py-5 text-center">
                    <div class="container">
                        <div class="content mb-5 pb-4">
                            <h1>Build <span class="blue-gradient">brighter</span> futures hand in hand </h1>
                        </div>
                        <div class="row gy-5">
                            <div class="col-md-4">
                                <div class="icon-box">
                                    <div class="icon">
                                        <img src="images/shape1.png" />
                                    </div>
                                    <h3>Time saving</h3>
                                    <p>Streamline workflows, saving time and boosting efficiency.</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="icon-box">
                                    <div class="icon">
                                        <img src="images/shape2.png" />
                                    </div>
                                    <h3>Real Time Analytics</h3>
                                    <p>Instant insights into your data with our real-time analytics capabilities.</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="icon-box">
                                    <div class="icon">
                                        <img src="images/shape3.png" />
                                    </div>
                                    <h3>Cutting edge</h3>
                                    <p>Updated with the latest trends and techniques in data and analytics.</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="icon-box">
                                    <div class="icon">
                                        <img src="images/game-icons.png" />
                                    </div>
                                    <h3>Continuous Learning</h3>
                                    <p>Constantly learning from interactions and feedback improving their capabilities. </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="icon-box">
                                    <div class="icon">
                                        <img src="images/shape5.png" />
                                    </div>
                                    <h3>Voice recognition feature</h3>
                                    <p>Natural conversation is integrated between the users and the AI with the integration of voice.</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="icon-box">
                                    <div class="icon">
                                        <img src="images/shape6.png" />
                                    </div>
                                    <h3>24/7 Availability</h3>
                                    <p>Available around the clock providing instant support and guidance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <section class="new-era py-5" id='serviceSection'>
                    <div class="container">
                        <div class="era-text text-center pb-5 mb-3">
                            <h1>Step into the future of AI  and experience<br /> the evolution of <span class="blue-gradient">Data & Analytics</span></h1>
                        </div>
                        <div class="row gy-5 align-items-center">
                            <div class="col-md-6">
                                <div class="text">
                                    <h6>Conversational Data Analyst Agent</h6>
                                    <h3>Customized queries</h3>
                                    <p>Tailored recommendations and insights based on your preferences and historical data.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="demo-img">
                                    <img src="images/analyst1.png" />
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="demo-img">
                                    <img src="images/analyst2.png" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text">
                                    <h6>Conversational MLOps Agent </h6>
                                    <h3>Highly efficient and scalable</h3>
                                    <p>Can handle the scaling of ML models and infrastructure, allowing for more robust and flexible ML deployments.</p>
                                </div>
                            </div>
                            <div class="nav-btn text-center mt-5">
                                <Link to="/service">View More</Link>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section class="accordion py-5">
                    <div class="container">
                        <h2 class="mb-4">FAQs</h2>
                        <div class="">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            How to book a demo?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            To book a demo, simply click on the 'Book Demo' button on the top right of the page and follow the instructions given. You
                                            can also contact us via the provided contact form for further assistance.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            What is the benefit of the Data Analyst Agent?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            It helps in analysing and comprehending data by also providing services like data transformation, data filtering, data visualization, etc. That makes the job of a data engineer easy.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Is the MLOps Agent easy to integrate?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes, all the agents that are created at AgentAnalytics.AI are easy to integrate and feasable to use.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="chatbot py-5">
                    <div class="container">
                        <div class="content text-center">
                            <h1>Need a domain based <span class="blue-gradient">Conversational AI Co-worker</span>?</h1>
                            <p class="pt-3">We can help you build one that fits your business needs</p>
                            <div class="nav-btn mt-5">
                            <Link onClick={handleContactClick}>Book a Demo</Link>
                                <Link class="light-bg ms-4" onClick={handleContactClick}>Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </>
    );

};

export default HomePage;
