import React, { useEffect } from 'react';
import './MlOpsAgent.css';
const MlOpsAgent = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <section class="after-banner ptCustom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="content">
              <h1>MlOps Agent</h1>
              <p className='text-dark'>AI Summer is here and more than million models have been created in last decade. With more AI models and foundational models on the way, world needs an AI Adoption Co-worker which can help productionize your model in fast, effective and secure way.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="feature-img">
                   <video width="560" height="400" autoPlay muted>
                     <source src="\vid\mlops.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                    </video>
            </div>
          </div>
          <div className='col-md-12 my-4'>
            <p className='text-dark'>This focuses on the concept of intelligent automation, where AI and machine learning are used to automate repetitive tasks and make data-driven decisions within the MLOps process. The field of MLOps is constantly evolving, and the integration of AI is likely to become more sophisticated in the future. The possibility of more advanced AI agents managing parts of the MLOps workflow might emerge over time. When discussing MLOps AI agents, it's important to clarify the specific functionalities being referred to. Different vendors or solutions might offer varying capabilities under this umbrella term.
            </p>
          </div>
        </div>
      </div>
    </section>
  );

};

export default MlOpsAgent;
