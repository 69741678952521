import React, { useEffect } from 'react';
import './Service.css';

const Service = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section class="new-era ptCustom pb-5" id='serviceSection'>
      <div class="container">
        <div class="era-text text-center pb-5 mb-3">
          <h1>Welcome to the new era of data & analysis <br /> with  <span class="clr-purple">AgentAnalytics.AI</span></h1>
        </div>
        <div class="row gy-5 align-items-center">
          <div class="col-md-6">
            <div class="text">
              <h6>Conversational Data Analyst Agent</h6>
              <h3>Analysis for every user</h3>
              <p>Lorem ipsum dolor sit amet. At voluptatibus ipsam aut culpa maxime est eaque error rem deserunt provident et vero odio est rerum assumenda. 
                Id sint maxime a dolor voluptatem ab galisum sunt ut architecto natus non facere magni.</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="demo-img">
              <img src="images/analyst1.png" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="demo-img">
              <img src="images/analyst2.png" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="text">
              <h6>Core Analytics Engine </h6>
              <h3>Analyst-grade answers to every question</h3>
              <p>Lorem ipsum dolor sit amet. At voluptatibus ipsam aut culpa maxime est eaque error rem deserunt provident et vero odio est rerum assumenda. 
                Id sint maxime a dolor voluptatem ab galisum sunt ut architecto natus non facere magni.</p>
            </div>
          </div>
        </div>

        <div class="era-text text-center pb-5 mb-3">
          <h1>Welcome to the new era of data analysis <br /> with  <span class="clr-purple">DataGPT</span></h1>
        </div>
        <div class="row gy-5 align-items-center">
          <div class="col-md-6">
            <div class="text">
              <h6>Conversational Data Analyst Agent</h6>
              <h3>Analysis for every user</h3>
              <p>Lorem ipsum dolor sit amet. At voluptatibus ipsam aut culpa maxime est eaque error rem deserunt provident et vero odio est rerum assumenda. 
                Id sint maxime a dolor voluptatem ab galisum sunt ut architecto natus non facere magni.</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="demo-img">
              <img src="images/analyst1.png" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="demo-img">
              <img src="images/analyst2.png" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="text">
              <h6>Core Analytics Engine </h6>
              <h3>Analyst-grade answers to every question</h3>
              <p>Lorem ipsum dolor sit amet. At voluptatibus ipsam aut culpa maxime est eaque error rem deserunt provident et vero odio est rerum assumenda. 
                Id sint maxime a dolor voluptatem ab galisum sunt ut architecto natus non facere magni.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

};

export default Service;
