import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import HomePage from './Pages/HomePage/HomePage';
import DataAnalyst from './Pages/DataAnalyst/DataAnalyst';
import Header from './components/Header/Header';
import MlOpsAgent from './Pages/MlOpsAgent/MlOpsAgent';
import Service from './Pages/Service/Service';
import Footer from './components/Footer/Footer';

function App() {
  return (
      <div className='content h-100'>
        <Router>
        <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/dataAnalyst" element={<DataAnalyst />} />
            <Route path="/mlAgent" element={<MlOpsAgent />} />
            <Route path="/service" element={<Service />} />
          </Routes>
          <Footer />
        </Router>
      </div>
  );
}

export default App;
