import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();
    const scrolldataSection = () => {
        const dataSection = document.getElementById('dataSection');
        dataSection.scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <>
       <footer class="pt-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col col-md-4">
                    <div class="logo">
                        <img src="images/logo.png" alt="" />
                    </div>
                </div>
                <div class="col">
                    <div class="footer-menu">
                        <h6>Products</h6>
                        <ul>
                            <li>
                            {location.pathname === '/service' || location.pathname === '/dataAnalyst' || location.pathname === '/mlAgent' ? (
                                    <Link to="/dataSection" className="text-dark">
                                         AI Co-workers
                                    </Link>
                                        ) : (
                                            <Link to="#" onClick={scrolldataSection} className="text-dark">
                                                 AI Co-workers
                                            </Link>
                                 )}
                            </li>
                            <li>Features</li>
                            <li>Services</li>
                            <li>Technology</li>
                        </ul>
                    </div>
                </div>
                <div class="col ">
                    <div class="footer-menu">
                        <h6>Services</h6>
                        <ul>
                            <li>B2B</li>
                            <li>Enterprise</li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div class="col ">
                    <div class="footer-menu">
                        <h6>Resources</h6>
                        <ul>
                            <li>Blog</li>
                            <li>Case studies</li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div class="col ">
                    <div class="footer-menu">
                        <h6>Company</h6>
                        <ul>
                            <li>About</li>
                            <li>Contact</li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>

                <div class="bottom-footer mt-5 pb-3">
                    <ul class="p-0 m-0 d-flex gap-4 justify-content-center">
                        <li>
                            © 2024 AgentAnalytics.AI
                        </li>
                        <li>Privacy</li>
                        <li>Terms</li>
                        <li>DPA</li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
    </>
  );

};

export default Footer;
